import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Form, Input, Button, Select, Row, Col, message } from 'antd'
import Icon, { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './Detail.module.css'
import util from '../../../libs/util'
import { Crumb, PreviewImg } from '../../../components'
import { appContext } from '../../../App.state'
import { cinemaPointStatus, mediaApproveStatusItems, approveStatusItems } from '../list/filterPanel/FilterPanel'
import { deviceOnlineItems, operatorTypeList } from '../list/listInner'
import { StockList } from './stock'
import { Sole } from './sole'
import { Audit } from './audit'
const { TextArea } = Input
interface Props {
  match: {
    params: {
      id?: String,
      isEdit?: Boolean,
      isAudit?: String,
    },
  },
}
// 准投内容类型
const contentCapabilityItems = [
  {
    itemKey: 'IMAGE',
    itemValue: '图文',
  },
  {
    itemKey: 'VIDEO',
    itemValue: '视频',
  },
]
// 支持格式
const formatSupportedItems = [
  {
    itemKey: 'jpg',
    itemValue: 'jpg',
  },
  {
    itemKey: 'jpeg',
    itemValue: 'jpeg',
  },
  {
    itemKey: 'png',
    itemValue: 'png',
  },
  {
    itemKey: 'mp4',
    itemValue: 'mp4',
  },
  {
    itemKey: 'mp3',
    itemValue: 'mp3',
  },
]
export const PointDetail: React.FC<Props> = (props) => {
  const appValue = useContext(appContext)
	const history = useHistory()
  const { id, isEdit, isAudit } = props.match.params
  const [crumbList, setCrumbList] = useState([
    {
      name: '媒体资源',
      path: '/entity/list',
    },
    {
      name: '点位管理',
      path: '/point/list',
    },
    {
      name: '银幕点位详情',
      path: '',
    }
  ])

  const cinemaScreenTypeItems = [
    {
      itemKey: 'SCOPE',
      itemValue: '宽幅银幕 2.39:1',
    },
    {
      itemKey: 'FLAT',
      itemValue: '遮幅银幕 1.85:1',
    },
    // {
    //   itemKey: 'SCOPE',
    //   itemValue: '宽幅银幕',
    //   tips: '2.39:1',
    // },
    // {
    //   itemKey: 'FLAT',
    //   itemValue: '遮幅银幕',
    //   tips: '1.85:1',
    // },
  ]

  useEffect(() => {
    init()
  }, [])
  const init = () => {
    getDetail()
    getEnumItems()
  }
  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<any[]>([])
  const [cinemaSoundTrackItems, setCinemaSoundTrackItems] = useState<any[]>([])
  // const [cinemaScreenTypeItems, setCinemaScreenTypeItems] = useState<any[]>([])
  const [lightboxMaterialItems, setLightboxMaterialItems] = useState<any[]>([])
  const [lcdDeviceTypeItems, setLcdDeviceTypeItems] = useState<any[]>([])
  const [lcdScreenTypeItems, setLcdScreenTypeItems] = useState<any[]>([])
  const [lightboxLocationTypeItems, setLightboxLocationTypeItems] = useState<any[]>([])

  // 最少需要上传的照片数
  const [minLen, setMinLen] = useState(1)
  
  // 获取枚举值
  const getEnumItems = async () => {
    // CINEMA_SOUND_TRACK 声道
    // CINEMA_SCREEN_MATERIAL 影院银幕类型
    // LIGHTBOX_MATERIAL 画面材质
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: ['CINEMA_SOUND_TRACK', 'CINEMA_SCREEN_MATERIAL', 'LIGHTBOX_MATERIAL', 'SCREEN_TYPE', 'DEVICE_TYPE', 'LIGHTBOX_LOCATION_TYPE'].join(','),
      },
    })
    if (code === 1) {
      setCinemaSoundTrackItems(data['CINEMA_SOUND_TRACK'] || [])
      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      // setCinemaScreenTypeItems(data['SCREEN_TYPE'] || [])
      setLightboxMaterialItems(data['LIGHTBOX_MATERIAL'] || [])
      setLcdDeviceTypeItems(data['DEVICE_TYPE'] || [])
      setLcdScreenTypeItems(data['SCREEN_TYPE'] || [])
      setLightboxLocationTypeItems(data['LIGHTBOX_LOCATION_TYPE'] || [])
    }
  }

  const [pointDetailForm] = Form.useForm()
  const inputStyle = {
    borderRadius: '4px', 
    borderColor: '#E6E6E6'
  }

  const [detail, setDetail] = useState<any>(null)
  // 获取枚举值
  const getDetail = async () => {
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/spu/getPointSpu`,
      data: {
        resourcePointId: id,
        saleModel: 'SPLIT',
      },
    })
    if (code === 1) {
      setDetail(data)
      pointDetailForm.setFieldsValue(data)
      const obj = operatorTypeList.find(i => i.itemKey === data.operatorType)
      const operatorTypeName = obj ? obj.itemValue : '数字屏'
      pointDetailForm.setFieldsValue({
        entityName: data.entityInfo?.entityName,
        provinceName: data.entityInfo?.provinceName,
        cityName: data.entityInfo?.cityName,
        operatorTypeName,
        lcdResolutionWidth: data.lcdResolutionWidth ? `${ util.tools.splitNum2StringFixed(data.lcdResolutionWidth) } * ${ util.tools.splitNum2StringFixed(data.lcdResolutionHeight) }` : '',
        lightboxFrameWidth: data.lightboxFrameWidth ? `${ util.tools.splitNum2StringFixed(data.lightboxFrameWidth) } * ${ util.tools.splitNum2StringFixed(data.lightboxFrameHeight) }` : '',
        lightboxContentWidth: data.lightboxContentWidth ? `${ util.tools.splitNum2StringFixed(data.lightboxContentWidth) } * ${ util.tools.splitNum2StringFixed(data.lightboxContentHeight) }` : '',
      })

      let cList = crumbList
      let lastItem = cList[cList.length - 1]
      lastItem.name = `${ operatorTypeName }点位详情`
      setCrumbList(cList)
    
      const operatorType = data.operatorType
      const storeItem = {
        itemKey: 'store',
        itemValue: '点位库存',
      }
      const auditItem = {
        itemKey: 'audit',
        itemValue: '审核记录',
      }
      if (operatorType === 'CINEMA') {
        const list = [storeItem, auditItem]
        setTabList(list)
        setTabId(list[0].itemKey)
      }
      auditRef && auditRef.current && auditRef.current.init()
    }
  }

  const getStatus = (status) => {
    return cinemaPointStatus.find(i => i.itemKey === detail?.releaseStatus)
  }

  const [tabList, setTabList] = useState<any>([])
  const [tabId, setTabId] = useState('audit')
  const toggleTab = (id) => {
    setTabId(id)
    if (id === 'audit') {
      auditRef && auditRef.current && auditRef.current.init()
    }
  }

  const [auditId, setAuditId] = useState('')
  const [auditList, setAuditList] = useState<any>([
    {
      itemKey: 'YES',
      itemValue: '通过',
      icon: 'check',
    },
    {
      itemKey: 'NO',
      itemValue: '不通过',
      icon: 'close',
    },
  ])
  const [approveOpinion, setApproveOpinion] = useState('')
  const onApproveOpinionChange = ({ currentTarget }) => {
    setApproveOpinion(currentTarget.value)
  }
  const toggleAudit = (id) => {
    setAuditId(id)
    setApproveOpinion('')
  }

  const onAudit = async() => {
    let params = {
      mediaApproveOpinion: auditId === 'NO' ? [approveOpinion] : null,
      mallPointSpuApproveId: detail.lastMallPointSpuApproveId,
      mediaApproveStatus: auditId,
    }
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/spu/mediaApprovePointRelease`,
      data: params,
    })
    if (code === 1) {
      message.success('操作成功！')
      history.push(`/point/detail/${ detail.resourcePointId }/false`)
      init()
    }
  }

  const auditRef: any = useRef()

  const previewImgRef: any = useRef()
  const previewImage = (url) => {
    previewImgRef.current.onToggle(url)
  }

  return (
    <div className={ `${ styles['point-page'] }` }>
      <div className={ `${ styles['point-page-tit'] }` }>
        <Crumb list={ crumbList } />
      </div>

      <div className={ `${ styles['point-cont'] }` }>
        <div className={ `${ styles['point-cont-tit'] }` }>基础信息</div>
        <div className={ `${ styles['point-status'] } ${ getStatus(detail?.releaseStatus)?.className === 'warn' ? styles['warn'] : styles['success'] }` }>{ getStatus(detail?.releaseStatus)?.itemValue }</div>
        <Form 
          layout="vertical"
          size="large"
          form={ pointDetailForm }
          name="pointDetailForm"
          autoComplete="off"
          >
          <Row gutter={ 24 }>
            <Col span={ 6 }>
              <Form.Item
                label="资源类型"
                name="operatorTypeName"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            {
              (detail?.operatorType === 'LCD' || detail?.operatorType === 'LED') && (
                <Col span={ 6 }>
                  <Form.Item
                    label="数字屏类型"
                    name="operatorType"
                    >
                    <Input 
                      disabled={ !isEdit }
                      style={ inputStyle }
                      placeholder="" 
                    />
                  </Form.Item>
                </Col>
              )
            }
            <Col span={ 6 }>
              <Form.Item
                label="点位编号"
                name="pointNumber"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                label="场所名称"
                name="entityName"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                label="省份"
                name="provinceName"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                label="城市"
                name="cityName"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                required
                label="点位名称"
                name="pointName"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                />
              </Form.Item>
            </Col>
            {
              (detail?.operatorType === 'LIGHTBOX') && (
                <Col span={ 6 }>
                  <Form.Item 
                    label="位置描述"
                    name="lightboxLocationType"
                    >
                    <Select
                      disabled={ !isEdit }
                      style={ inputStyle }
                      allowClear
                      >
                      {
                        lightboxLocationTypeItems.map((record, index) => {
                          return (
                            <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              )
            }
            {
              (detail?.operatorType === 'LCD' || detail?.operatorType === 'LED' || detail?.operatorType === 'LIGHTBOX') && (
                <Col span={ 6 }>
                  <Form.Item
                    required
                    label="位置描述"
                    name={ `${ detail?.operatorType === 'LCD' ? 'lcdLocationInfo' : (detail?.operatorType === 'LED' ? 'ledLocationInfo' : 'lightboxLocationInfo') }` }
                    >
                    <Input 
                      disabled={ !isEdit }
                      style={ inputStyle }
                      placeholder="" 
                    />
                  </Form.Item>
                </Col>
              )
            }
            {
              (detail?.operatorType === 'CINEMA' || detail?.operatorType === 'HALL_NAME') && (
                <Col span={ 6 }>
                  <Form.Item
                    required
                    label="座位数"
                    name={ `${ detail?.operatorType === 'CINEMA' ? 'seatNumber' : 'hallNameSeatNumber' }` }
                    >
                    <Input 
                      disabled={ !isEdit }
                      style={ inputStyle }
                      placeholder="" 
                    />
                  </Form.Item>
                </Col>
              )
            }

            {
              (detail?.operatorType === 'CINEMA') && (
                <>
                <Col span={ 6 }>
              <Form.Item 
                required
                label="银幕类型"
                name="cinemaScreenMaterial"
                >
                <Select
                  disabled={ !isEdit }
                  style={ inputStyle }
                  allowClear
                  >
                  {
                    cinemaScreenMaterialItems.map((record, index) => {
                      return (
                        <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item 
                    required
                    label="声道"
                    name="cinemaSoundTrack"
                    >
                    <Select
                      disabled={ !isEdit }
                      style={ inputStyle }
                      allowClear
                      >
                      {
                        cinemaSoundTrackItems.map((record, index) => {
                          return (
                            <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item 
                    required
                    label="放映机播放格式"
                    name="cinemaFormatSupported"
                    >
                    <Input 
                      disabled={ !isEdit }
                      style={ inputStyle }
                      placeholder="" 
                    />
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item 
                    required
                    label="银幕画幅"
                    name="cinemaScreenType"
                    >
                    <Select
                      disabled={ !isEdit }
                      style={ inputStyle }
                      allowClear
                      >
                      {
                        cinemaScreenTypeItems.map((record, index) => {
                          return (
                            <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                </>
              )
            }

            {
              (detail?.operatorType === 'LCD') && (
                <>
                  <Col span={ 6 }>
                    <Form.Item 
                      label="设备类型"
                      name="lcdDeviceType"
                      >
                      <Select
                        disabled={ !isEdit }
                        style={ inputStyle }
                        allowClear
                        >
                        {
                          lcdDeviceTypeItems.map((record, index) => {
                            return (
                              <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )
            }

            {
              (detail?.operatorType === 'LCD') && (
                <>
                  <Col span={ 6 }>
                    <Form.Item 
                      label="显示方向"
                      name={ `${ detail?.operatorType === 'LCD' ? 'lcdScreenType' : 'ledScreenType' }` }
                      >
                      <Select
                        disabled={ !isEdit }
                        style={ inputStyle }
                        allowClear
                        >
                        {
                          lcdScreenTypeItems.map((record, index) => {
                            return (
                              <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      label="最佳分辨率"
                      name={ `${ detail?.operatorType === 'LCD' ? 'lcdResolutionWidth' : 'ledResolutionWidth' }` }
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="准投内容类型"
                      name={ `${ detail?.operatorType === 'LCD' ? 'lcdContentCapability' : 'ledContentCapability' }` }
                      >
                      <Select
                        disabled={ !isEdit }
                        style={ inputStyle }
                        allowClear
                        mode="multiple"
                        >
                        {
                          contentCapabilityItems.map((record, index) => {
                            return (
                              <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      label="支持格式"
                      name={ `${ detail?.operatorType === 'LCD' ? 'lcdFormatSupported' : 'ledFormatSupported' }` }
                      >
                      <Select
                        disabled={ !isEdit }
                        style={ inputStyle }
                        allowClear
                        mode="multiple"
                        >
                        {
                          formatSupportedItems.map((record, index) => {
                            return (
                              <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      label="屏幕尺寸"
                      name={ `${ detail?.operatorType === 'LCD' ? 'lcdScreenSize' : 'ledScreenSize' }` }
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                </>
              )
            }

            {
              (detail?.operatorType === 'HALL_NAME') && (
                <>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="厅号牌尺寸"
                      name="hallNameLabelSize"
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="制作尺寸"
                      name="hallNameContentSize"
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="椅套尺寸"
                      name="hallNameChairSize"
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                </>
              )
            }

            {
              (detail?.operatorType === 'LIGHTBOX') && (
                <>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="外框尺寸（cm）"
                      name="lightboxFrameWidth"
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="有效尺寸（cm）"
                      name="lightboxContentWidth"
                      >
                      <Input 
                        disabled={ !isEdit }
                        style={ inputStyle }
                        placeholder="" 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={ 6 }>
                    <Form.Item 
                      required
                      label="上刊画面材质"
                      name="lightboxMaterial"
                      >
                      <Select
                        disabled={ !isEdit }
                        style={ inputStyle }
                        allowClear
                        mode="multiple"
                        >
                        {
                          lightboxMaterialItems.map((record, index) => {
                            return (
                              <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )
            }

            <Col span={ 6 }>
              <Form.Item 
                required
                label="定价"
                name="mediaSalePrice"
                >
                <Input 
                  disabled={ !isEdit }
                  style={ inputStyle }
                  placeholder="" 
                  suffix={ (detail?.operatorType === 'CINEMA') ? '元/场/15秒' : (detail?.operatorType === 'LED' || detail?.operatorType === 'LCD') ? '元/天/15秒' : '元/天' }
                />
              </Form.Item>
            </Col>
            
            <Col span={ 6 }>
              <Form.Item
                required
                label="点位状态"
                name="releaseStatus"
                >
                <Select
                  disabled={ !isEdit }
                  style={ inputStyle }
                  allowClear
                  >
                  {
                    cinemaPointStatus.map((record, index) => {
                      return (
                        <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                required
                label={ detail?.operatorType === 'CINEMA' ? '企业审核状态' : '审核状态' }
                name="mediaApproveStatus"
                >
                <Select
                  disabled={ !isEdit }
                  style={ inputStyle }
                  allowClear
                  >
                  {
                    mediaApproveStatusItems.map((record, index) => {
                      return (
                        <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            {
              (detail?.operatorType === 'CINEMA') && (
                <Col span={ 6 }>
                  <Form.Item
                    required
                    label="平台审核状态"
                    name="approveStatus"
                    >
                    <Select
                      disabled={ !isEdit }
                      style={ inputStyle }
                      allowClear
                      >
                      {
                        approveStatusItems.map((record, index) => {
                          return (
                            <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              )
            }
            {
              (detail?.operatorType === 'CINEMA') && (
                <Col span={ 6 }>
                  <Form.Item
                    required
                    label="设备状态"
                    name="deviceOnline"
                    >
                    <Select
                      disabled={ !isEdit }
                      style={ inputStyle }
                      allowClear
                      >
                      {
                        deviceOnlineItems.map((record, index) => {
                          return (
                            <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              )
            }
          </Row>
          {
            (detail?.operatorType === 'HALL_NAME') && (
              <Row gutter={ 24 }>
                <Col span={ 6 }>
                  <Form.Item 
                    required
                    label="点位介绍"
                    name="hallNameDescription"
                    >
                    <TextArea 
                      rows={ 6 }
                      disabled={ !isEdit }
                      style={ inputStyle }
                      placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
            )
          }
          <Row gutter={ 24 }>
            <Col span={ 24 }>
              <Form.Item
                label="点位图片"
                name="img"
                required
                >
                {
                  (detail?.releaseStatus !== 'RELEASE') && (
                  <>
                    {/* <div className={ `${ styles['btn-example'] }` }>查看示例图</div> */}
                    <div className={ `${ styles['form-tips'] }` }>为保证点位的真实性，上传的点位图片必须为点位现场拍摄</div>
                  </>)
                }
                <div className={ `${ styles['img-list'] }` }>
                  {
                    detail?.environmentFilePaths?.length >= minLen && detail?.environmentFilePaths?.map((i, index) => {
                      return (
                        <div className={ `${ styles['img-item-wrap'] } ${ styles[detail?.operatorType] }` }>
                          {
                            <img key={ index } onClick={ () => { previewImage(`${ appValue.rHost }${ i }`) } } className={ `${ styles['img-item'] }` } src={ `${ appValue.rHost }${ i }?imageMogr2/thumbnail/x270 `} />
                          }
                        </div>
                      )
                    })
                  }
                  {
                    (!detail?.environmentFilePaths || detail?.environmentFilePaths?.length < minLen) && (
                      <div className={ `${ styles['img-item-wrap'] } ${ styles[detail?.operatorType] } ${ styles['default'] } ` }></div>
                    )
                  }
                </div>
                <PreviewImg childRef={ previewImgRef } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {
        tabList.length > 0 && (
        <div className={ `${ styles['point-cont'] }` }>
          <div className={ `${ styles['tab-list'] }` }>
            {
              tabList.map(tab => {
                return (
                  <div key={ tab.itemKey } className={ `${ styles['tab-item'] } ${ tabId === tab.itemKey ? styles['is-selected'] : '' }` } onClick={ () => { toggleTab(tab.itemKey) } }>{ tab.itemValue }</div>
                )
              })
            }
          </div>
          {
            tabId === 'store' && (
              <>
                <Sole resourcePointId={ detail?.resourcePointId } />
                <StockList id={ detail?.resourcePointId } />
              </>
            )
          }
  
          <div className={ `${ tabId === 'audit' ? styles['is-show'] : styles['is-hide'] } `}>
            <Audit childRef={ auditRef } resourcePointId={ detail?.resourcePointId } />
          </div>
        </div>
        )
      }

      {
        (detail?.mediaApproveStatus === 'WAIT' && isAudit === 'true') && (
          <div className={ `${ styles['point-cont'] }` }>
            <div className={ `${ styles['point-cont-tit'] }` }>审核</div>
            <div className={ `${ styles['audit-list'] }` }>
              {
                auditList.map(item => {
                  return (
                    <div 
                      className={ `${ styles['audit-item'] } ${ auditId === 'YES' && auditId === item.itemKey ? styles['is-success'] : '' } ${ auditId === 'NO' && auditId === item.itemKey ? styles['is-error'] : '' }` }
                      style={{
                        backgroundColor: `${ (auditId === 'YES' && auditId === item.itemKey) ? '#45C9B8' : ( auditId === 'NO' && auditId === item.itemKey ? '#FF4D4F' : '#fff') }`
                      }}
                      key={ item.itemKey }
                      onClick={ () => { toggleAudit(item.itemKey) } }
                      >
                      <span 
                        style={{
                          backgroundColor: `${ (auditId === 'YES' && auditId === item.itemKey) ? '#45C9B8' : ( auditId === 'NO' && auditId === item.itemKey ? '#FF4D4F' : '#fff') }`
                        }}
                        className={ `${ styles['audit-icon'] }` }>
                        { item.icon === 'check' ? <CheckOutlined style={{ fontSize: '18px', color: auditId === item.itemKey ? '#fff' : '#999' }} /> : <CloseOutlined style={{ fontSize: '18px', color: auditId === item.itemKey ? '#fff' : '#999' }} /> }
                      </span>
                      { item.itemValue }
                    </div>
                  )
                })
              }
            </div>
            {
              auditId === 'NO' && (
                <div className={ `${ styles['audit-list'] }` }>
                  <TextArea 
                    rows={ 6 }
                    defaultValue={ approveOpinion }
                    className={ `${ styles['audit-textarea'] }` }
                    onChange={ onApproveOpinionChange }
                    placeholder='请输入不通过原因' />
                </div>
              )
            }
            
            <div className={ `${ styles['point-audit-btn'] }` }>
              <Button 
                style={{
                  marginRight: '20px',
                  backgroundColor: '#E2A45A',
                  borderColor: '#E2A45A',
                  borderRadius: '4px',
                }}
                disabled={ !auditId }
                type="primary"
                onClick={ onAudit }
                >
                提交
              </Button>
            </div>
          </div>
        )
      }

      {/*  && isAudit !== 'true' */}
      {
        (tabList.length <= 0) && (
        <div className={ `${ styles['point-cont'] }` }>
          <div className={ `${ styles['point-cont-tit'] }` }>审核记录</div>
          <Audit childRef={ auditRef } resourcePointId={ detail?.resourcePointId } />
        </div>)
      }

    </div>
  )
}